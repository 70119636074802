import { MesoIntegrationProperty } from "../types";
import { Posthog } from "./posthog";
import { Sentry } from "./sentry";

export type InitializeTelemetryOptions = {
  sentry?: {
    /** @default false */
    useReactRouterV7BrowserTracingIntegration?: boolean;
    /** @default false */
    useBrowserTracingIntegration?: boolean;
    /**
     * Whether to attempt to tag Sentry events with the Meso JS version.
     * @default false
     */
    tagMesoJsVersion?: boolean;
  };
  posthog: { property: MesoIntegrationProperty };
};

export const initializeTelemetry = (options: InitializeTelemetryOptions) => {
  Sentry.init(options.sentry);
  Posthog.init(options.posthog);
};

export const updateConsent = (optIn: boolean) => {
  Sentry.updateConsent(optIn);
  Posthog.updateConsent(optIn);
};

export * from "./sentry";
export * from "./posthog";
export * from "./events";
