import {
  AddWalletMutation,
  AddWalletMutationVariables,
  ChangePasswordMutationVariables,
  NewSessionMutation,
  NewSessionMutationVariables,
  PerformEmailPasswordLoginMutation,
  PerformEmailPasswordLoginMutationVariables,
  PerformMagicLinkLoginMutation,
  PerformMagicLinkLoginMutationVariables,
  Send2FaCodeMutation,
  Send2FaCodeMutationVariables,
  SetThemeMutation,
  SetThemeMutationVariables,
  RemoveUserWalletMutationVariables,
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
  ResetPasswordMutationVariables,
  Verify2FaMutation,
  Verify2FaMutationVariables,
  getSdk,
  ChangePasswordMutation,
  TransfersQuery,
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables,
  ResendEmailVerificationMutationVariables,
  VerifyEmailMutationVariables,
  ExportTransfersMutation,
  UpdateDataConsentMutationVariables,
} from "./generated/sdk";
import responseMiddleware from "./utils/middleware";
import { ClientError, GraphQLClient } from "graphql-request";
import { ErrorMessages } from "./utils/errorMessages";
import { Result, err, ok } from "neverthrow";
import { User } from "./types";
import { useNavigate } from "react-router-dom";
import { Sentry } from "@tigris/common";
import {
  AddPaymentCardErrorCode,
  isAddPaymentCardErrorCode,
} from "@tigris/mesokit";
import { RequestConfig } from "node_modules/graphql-request/build/esm/types";

const MESO_AUTHORIZATION_HEADER = "Authorization";
const { VITE_GRAPHQL_ORIGIN } = import.meta.env;
const apiUrl =
  VITE_GRAPHQL_ORIGIN && typeof VITE_GRAPHQL_ORIGIN === "string"
    ? `${VITE_GRAPHQL_ORIGIN}/query`
    : `${location.origin}/query`;
var graphQLClient = new GraphQLClient(apiUrl, { errorPolicy: "all" });
export var sdk = getSdk(graphQLClient);

export const resetSessionIdentifier = () => {
  graphQLClient = new GraphQLClient(apiUrl);
  sdk = getSdk(graphQLClient);
};

export const setBearerToken = (
  token: string,
  navigate?: ReturnType<typeof useNavigate>,
) => {
  const requestConfig: RequestConfig = {
    errorPolicy: "all",
    headers: { [MESO_AUTHORIZATION_HEADER]: `Bearer ${token}` },
  };

  if (navigate) {
    requestConfig.responseMiddleware = responseMiddleware(navigate);
  }
  graphQLClient = new GraphQLClient(apiUrl, requestConfig);
  sdk = getSdk(graphQLClient);
};

export const reportApiError = (error: unknown, operation: string) => {
  Sentry.captureException(
    error instanceof ClientError ? error.message : error,
    { tags: { operation } },
  );
};

export type ResolveUserResult = Result<User, string>;
export const resolveUser = async (): Promise<ResolveUserResult> => {
  const ERROR_MESSAGE = "Unable to retrieve User data.";
  const OPERATION_NAME = "User";

  try {
    const result = await sdk[OPERATION_NAME]();
    const {
      data: { user },
    } = result;

    if (!result || !user || user.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(user);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }
  return err(ERROR_MESSAGE);
};

export type ResolveVerify2FAResult = Result<
  Extract<Verify2FaMutation["verify2FA"], { __typename: "BooleanObject" }>,
  string
>;
export const resolveVerify2FA = async ({
  input,
}: Verify2FaMutationVariables): Promise<ResolveVerify2FAResult> => {
  const ERROR_MESSAGE = "Invalid code. Please try again.";
  const OPERATION_NAME = "Verify2FA";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { verify2FA },
    } = result;

    if (
      !result ||
      !verify2FA ||
      verify2FA.__typename === "Errors" ||
      verify2FA.bool === false
    ) {
      if (verify2FA?.__typename === "Errors") {
        const errorCode = verify2FA.errors.at(0)?.code;
        if (errorCode !== "missing_phone_number") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
        }
      }

      return err(ERROR_MESSAGE);
    }

    return ok(verify2FA);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

type ResolveAddWalletSuccessResult = {
  addWallet: Extract<
    AddWalletMutation["addWallet"],
    { __typename: "ProfileStatus" }
  >;
  user: Extract<AddWalletMutation["user"], { __typename: "User" }>;
};

export const resolveAddWallet = async ({
  input,
}: AddWalletMutationVariables): Promise<
  Result<ResolveAddWalletSuccessResult, string>
> => {
  const ERROR_MESSAGE = "Unable to add wallet.";
  const USER_ERROR_MESSAGE = "Unable to retrieve User.";
  const OPERATION_NAME = "AddWallet";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { addWallet, user },
    } = result;

    if (!addWallet || addWallet.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      if (addWallet?.errors[0].code === "invalid_wallet_address") {
        return err(ErrorMessages.INVALID_WALLET_ADDRESS);
      }

      return err(ERROR_MESSAGE);
    }

    if (!user || user.__typename === "Errors") {
      Sentry.captureMessage(USER_ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(USER_ERROR_MESSAGE);
    }

    if (user.__typename === "User") {
      return ok({ addWallet, user });
    }
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

/**
 * Establish a new session with the Meso API.
 */
export type ResolveSessionResult = Result<
  Extract<NewSessionMutation["newSession"], { __typename: "Session" }>,
  string
>;
export const resolveSession = async ({
  input,
}: NewSessionMutationVariables): Promise<ResolveSessionResult> => {
  const OPERATION_NAME = "NewSession";
  const ERROR_MESSAGE = "Unable to create session.";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { newSession },
    } = result;

    if (!result || !newSession || newSession.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(newSession);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveSetThemeResult = Result<
  Extract<SetThemeMutation["setTheme"], { __typename: "BooleanObject" }>,
  string
>;
export const resolveSetTheme = async ({
  input,
}: SetThemeMutationVariables): Promise<ResolveSetThemeResult> => {
  const ERROR_MESSAGE = "Unable to set theme";
  const OPERATION_NAME = "SetTheme";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { setTheme },
    } = result;

    if (!result || !setTheme || setTheme?.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });
      return err(ERROR_MESSAGE);
    }

    return ok(setTheme);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export const resolveSend2FACode = async ({
  input,
}: Send2FaCodeMutationVariables): Promise<
  Result<
    Extract<
      Send2FaCodeMutation["send2FACode"],
      { __typename: "BooleanObject" }
    >,
    string
  >
> => {
  const ERROR_MESSAGE = "Unable to resend 2FA code.";
  const OPERATION_NAME = "Send2FACode";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { send2FACode },
    } = result;

    if (
      !result ||
      !send2FACode ||
      send2FACode.__typename === "Errors" ||
      send2FACode.bool === false
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      if (
        send2FACode?.__typename === "Errors" &&
        send2FACode.errors.find((e) => e.code === "unsupported_phone_number")
      ) {
        return err(ErrorMessages.twoFactorAuth.UNSUPPORTED_PHONE_NUMBER_ERROR);
      }

      return err(ERROR_MESSAGE);
    }

    return ok(send2FACode);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export const resolvePerformMagicLinkLogin = async ({
  input,
}: PerformMagicLinkLoginMutationVariables): Promise<
  Result<
    Extract<
      PerformMagicLinkLoginMutation["performMagicLinkLogin"],
      { __typename: "Login" }
    >,
    string
  >
> => {
  const ERROR_MESSAGE = "Unable to resolve magic link code.";
  const OPERATION_NAME = "PerformMagicLinkLogin";

  try {
    const result = await sdk[OPERATION_NAME]({
      input,
    });
    const {
      data: { performMagicLinkLogin },
    } = result;

    if (
      !result ||
      !performMagicLinkLogin ||
      performMagicLinkLogin.__typename === "Errors"
    ) {
      return err(ERROR_MESSAGE);
    }

    return ok(performMagicLinkLogin);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolvePerformEmailPasswordLoginResult = Result<
  Extract<
    PerformEmailPasswordLoginMutation["performEmailPasswordLogin"],
    { __typename: "Login" }
  >,
  string
>;
export const resolvePerformEmailPasswordLogin = async ({
  input,
}: PerformEmailPasswordLoginMutationVariables): Promise<ResolvePerformEmailPasswordLoginResult> => {
  const ERROR_MESSAGE = "Unable to perform login. Please contact support.";
  const OPERATION_NAME = "PerformEmailPasswordLogin";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { performEmailPasswordLogin },
    } = result;

    if (
      !performEmailPasswordLogin ||
      performEmailPasswordLogin.__typename === "Errors"
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(performEmailPasswordLogin);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveChangePasswordResult = Result<
  Extract<
    ChangePasswordMutation["changePassword"],
    { __typename: "BooleanObject" }
  >,
  string
>;
export const resolveChangePassword = async ({
  input,
}: ChangePasswordMutationVariables): Promise<ResolveChangePasswordResult> => {
  const ERROR_MESSAGE = ErrorMessages.changePassword.UNABLE_TO_CHANGE_PASSWORD;
  const OPERATION_NAME = "ChangePassword";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { changePassword },
    } = result;

    if (!changePassword || changePassword.__typename === "Errors") {
      if (changePassword?.errors[0].code === "password_reused") {
        return err(ErrorMessages.changePassword.REUSED);
      } else if (changePassword?.errors[0].code === "bad_current_password") {
        return err(ErrorMessages.changePassword.BAD_CURRENT_PASSWORD);
      } else {
        Sentry.captureMessage(ERROR_MESSAGE, {
          level: "warning",
          tags: { operation: OPERATION_NAME },
          extra: {
            result: JSON.stringify(result),
            requestId: result.headers.get("x-meso-request") ?? "unknown",
          },
        });
      }

      return err(ERROR_MESSAGE);
    }

    if (!changePassword.bool) {
      return err(ERROR_MESSAGE);
    }

    return ok(changePassword);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveRequestPasswordResetResult = Result<
  Extract<
    RequestPasswordResetMutation["requestPasswordReset"],
    { __typename: "BooleanObject" }
  >,
  string
>;

export const resolveRequestPasswordReset = async ({
  input,
}: RequestPasswordResetMutationVariables): Promise<ResolveRequestPasswordResetResult> => {
  const ERROR_MESSAGE =
    "Unable to request password reset. Please contact support.";
  const OPERATION_NAME = "RequestPasswordReset";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { requestPasswordReset },
    } = result;

    if (
      !result ||
      !requestPasswordReset ||
      requestPasswordReset.__typename === "Errors"
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(requestPasswordReset);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export const resolveResetPassword = async ({
  input,
}: ResetPasswordMutationVariables): Promise<Result<true, string>> => {
  const ERROR_MESSAGE = ErrorMessages.resetPassword.UNABLE_TO_RESET_PASSWORD;
  const OPERATION_NAME = "ResetPassword";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { resetPassword },
    } = result;

    if (!result || !resetPassword) {
      return err(ERROR_MESSAGE);
    }

    if (resetPassword.__typename === "Errors") {
      if (resetPassword?.errors[0].code === "password_reused") {
        return err(ErrorMessages.resetPassword.REUSED);
      } else if (
        resetPassword?.errors[0].code === "unauthorized_password_reset"
      ) {
        return err(ErrorMessages.resetPassword.UNAUTHORIZED);
      } else {
        Sentry.captureMessage(ERROR_MESSAGE, {
          level: "warning",
          tags: { operation: OPERATION_NAME },
          extra: {
            result: JSON.stringify(result),
            requestId: result.headers.get("x-meso-request") ?? "unknown",
          },
        });
      }

      return err(ERROR_MESSAGE);
    }

    if (!resetPassword.bool) {
      return err(ERROR_MESSAGE);
    }

    return ok(resetPassword.bool);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

type ResolveRemoveUserWalletResult = Result<true, string>;
export const resolveRemoveUserWallet = async ({
  input,
}: RemoveUserWalletMutationVariables): Promise<ResolveRemoveUserWalletResult> => {
  const OPERATION_NAME = "RemoveUserWallet";
  const ERROR_MESSAGE = ErrorMessages.removeWallet.UNABLE_TO_REMOVE_WALLET;

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { removeUserWallet },
    } = result;

    if (
      !result ||
      !removeUserWallet ||
      removeUserWallet.__typename === "Errors" ||
      !removeUserWallet.bool
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: { result: JSON.stringify(result) },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(removeUserWallet.bool);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveTransfersResult = Result<
  Extract<
    TransfersQuery["transfers"],
    { __typename?: "Transfers" }
  >["collection"],
  string
>;
export const resolveTransfers = async (): Promise<ResolveTransfersResult> => {
  const OPERATION_NAME = "Transfers";
  const ERROR_MESSAGE = "Unable to fetch transfers.";

  try {
    const result = await sdk[OPERATION_NAME]();
    const {
      data: { transfers },
    } = result;

    if (!result || !transfers || transfers.__typename !== "Transfers") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });

      return err(ERROR_MESSAGE);
    }
    return ok(transfers.collection);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveAddPaymentCardResult = Result<
  {
    addPaymentCard: Extract<
      AddPaymentCardMutation["addPaymentCard"],
      { __typename: "ProfileStatus" }
    >;
    user: Extract<AddPaymentCardMutation["user"], { __typename: "User" }>;
  },
  {
    code: AddPaymentCardErrorCode;
  }
>;

export const resolveAddPaymentCard = async ({
  input,
}: AddPaymentCardMutationVariables): Promise<ResolveAddPaymentCardResult> => {
  const OPERATION_NAME = "AddPaymentCard";
  const defaultError: { code: AddPaymentCardErrorCode } = {
    code: "unknown",
  };

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { addPaymentCard, user },
    } = result;

    if (!result || !addPaymentCard || !user) {
      return err(defaultError);
    }

    if (addPaymentCard.__typename === "Errors") {
      const errorCode = addPaymentCard.errors[0].code;

      if (isAddPaymentCardErrorCode(errorCode)) {
        return err({ code: errorCode });
      }

      return err(defaultError);
    }

    if (user.__typename === "User") {
      return ok({ addPaymentCard, user });
    }

    return err(defaultError);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(defaultError);
};

export enum ResendEmailVerificationError {
  UNABLE_TO_RESEND = "UNABLE_TO_RESEND",
  TERMINAL_ERROR = "TERMINAL_ERROR",
}

export const resolveResendEmailVerification = async ({
  input,
}: ResendEmailVerificationMutationVariables): Promise<
  Result<undefined, ResendEmailVerificationError>
> => {
  const OPERATION_NAME = "ResendEmailVerification";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { resendEmailVerification },
    } = result;

    if (
      !result ||
      !resendEmailVerification ||
      resendEmailVerification.__typename === "Errors" ||
      resendEmailVerification.bool === false
    ) {
      return err(ResendEmailVerificationError.UNABLE_TO_RESEND);
    }

    return ok(undefined);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ResendEmailVerificationError.TERMINAL_ERROR);
};

/** Domain-specific interpretation of API errors for verifying email. */
export enum VerifyEmailError {
  /** The code provided is invalid. The user can retry a different code. */
  INVALID_CODE = "INVALID_CODE",
  /** The verification failed, likely because the code is expired. */
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  /** A non-specific error occurred. Recovery strategy unknown */
  GENERIC = "GENERIC",
}

export const resolveVerifyEmail = async ({
  input,
}: VerifyEmailMutationVariables): Promise<
  Result<undefined, VerifyEmailError>
> => {
  const OPERATION_NAME = "VerifyEmail";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { verifyEmail },
    } = result;

    if (!result || !verifyEmail) {
      return err(VerifyEmailError.GENERIC);
    }

    if (verifyEmail.__typename === "Errors") {
      if (verifyEmail?.errors[0].code === "verification_code_invalid") {
        return err(VerifyEmailError.INVALID_CODE);
      }

      return err(VerifyEmailError.GENERIC);
    }

    if (verifyEmail.bool === false) {
      return err(VerifyEmailError.VERIFICATION_FAILED);
    }

    return ok(undefined);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(VerifyEmailError.GENERIC);
};

export const resolveExportTransfers = async (): Promise<
  Result<
    Extract<
      ExportTransfersMutation["exportTransfers"],
      { __typename: "TransfersExport" }
    >["csvContents"],
    string
  >
> => {
  const OPERATION_NAME = "ExportTransfers";

  try {
    const result = await sdk[OPERATION_NAME]();
    const {
      data: { exportTransfers },
    } = result;

    if (
      !result ||
      !exportTransfers ||
      exportTransfers.__typename === "Errors"
    ) {
      return err(ErrorMessages.downloadTransfers.UNABLE_TO_DOWNLOAD_TRANSFERS);
    }

    return ok(exportTransfers.csvContents);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ErrorMessages.downloadTransfers.UNABLE_TO_DOWNLOAD_TRANSFERS);
};

export const resolveUpdateDataConsent = async ({
  input,
}: UpdateDataConsentMutationVariables): Promise<Result<true, string>> => {
  const OPERATION_NAME = "UpdateDataConsent";
  const ERROR_MESSAGE = ErrorMessages.dataConsent.GENERIC_ERROR;

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const {
      data: { updateDataConsent },
    } = result;

    if (
      !updateDataConsent ||
      updateDataConsent.__typename === "Errors" ||
      !updateDataConsent.success
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: {
          result: JSON.stringify(result),
          requestId: result.headers.get("x-meso-request") ?? "unknown",
        },
      });
      return err(ERROR_MESSAGE);
    }

    return ok(true);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};
