/**
 * Custom JSX content for rendering into a `toast.error()` call.
 *
 * Sonner does not render an icon when passing JSX to a toast so we have to do it manually.
 */
export const CustomToastError = ({
  title,
  body,
}: {
  title: string;
  body?: string | React.JSX.Element;
}) => {
  return (
    <div className="flex items-center gap-3">
      <div className="">
        <div className="font-bold">{title}</div>
        {body ?? (
          <div>
            Try again or{" "}
            <a
              href="https://support.meso.network"
              target="_blank"
              rel="noreferrer"
              className="underline opacity-80 transition-opacity hover:opacity-100"
            >
              contact support
            </a>
            .
          </div>
        )}
      </div>
    </div>
  );
};
