import { ErrorMessages } from "../utils/errorMessages";
import { CustomToastError } from "./CustomToastError";

export const UnsupportedPhoneNumberToastError = () => {
  return (
    <CustomToastError
      title={ErrorMessages.twoFactorAuth.UNSUPPORTED_PHONE_NUMBER_ERROR}
      body={
        <div>
          See{" "}
          <a
            href="https://support.meso.network/hc/en-us/articles/17053649941787-Two-Factor-Authentication-2FA"
            target="_blank"
            rel="noreferrer"
            className="underline opacity-80 transition-opacity hover:opacity-100"
          >
            this article
          </a>{" "}
          for details on supported phone numbers.
        </div>
      }
    />
  );
};
